<template>
  <div class="form-error os-form__section">
    <div class="os-form__header">
      <h2 class="os-form__title">{{ formErrorBag.errorTitle }}</h2>
    </div>

    <p v-if="formErrorBag.hasErrors">
      {{ formErrorBag.first }}
    </p>
    <p v-else-if="isRetailerError">
      {{ retailerErrorMessage }}
    </p>

    <p v-if="formErrorBag.showDefaultError">
      Feel free to

      <a
        v-if="formErrorBag.showStartAgainButton"
        class="inline-link bold"
        @click="startAgain"
      >
        try again
      </a>
      <span v-else> try again later </span>

      <template v-if="isChatOnline">
        or talk to us using
        <OsChatButton
          :text-only="true"
          class="inline-link form-error__inline-chat"
          :ga-category="'formError:inline'"
        >
          Live chat
        </OsChatButton>
      </template>
      .
    </p>

    <OsButtonTray v-if="formErrorBag.showStartAgainButton || isChatOnline">
      <OsButton
        v-if="
          formErrorBag.showStartAgainButton &&
          (!isChatOnline || formErrorBag.hasErrors)
        "
        is-primary
        :aria-label="formErrorBag.buttonText"
        @click.prevent="startAgain"
      >
        {{ formErrorBag.buttonText }}
      </OsButton>
      <OsChatButton
        v-else
        class="form-error__chatButton"
        :ga-category="'formError:tray'"
        :text-only="true"
        :colour="'#ffffff'"
      >
        Start using Live Chat
      </OsChatButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { EFormType } from '~/types/forms'
import { useMessengerStore } from '~/stores/messengerStore'
import { errorMessages } from '~/constants/forms'
import { useRetailerStore } from '~/stores/retailerStore'

export default {
  props: {
    formErrorBag: {
      type: Object as PropType<FormErrorBag>,
      required: false,
      default: () => new FormErrorBag()
    },
    formType: {
      type: String as PropType<EFormType>,
      required: true
    }
  },

  emits: ['start-again'],

  setup(props, { emit }) {
    const { $dataLayer } = useNuxtApp()
    const messengerStore = useMessengerStore()
    const { retailer } = useRetailerStore()

    const startAgain = () => {
      emit('start-again')
    }

    const isRetailerError = computed(() => !retailer)

    const retailerErrorMessage = computed<string>(() => {
      return errorMessages.reloadFormError
    })

    const isChatOnline = computed(() => messengerStore.isOnline)

    onMounted(() => {
      $dataLayer.pageView({
        pageCategory: `${props.formType.toLowerCase()}:error`,
        ...props.formErrorBag.trackingOptions
      })
    })

    return {
      isRetailerError,
      isChatOnline,
      startAgain,
      retailerErrorMessage
    }
  }
}
</script>

<style lang="scss" scoped>
.form-error {
  .inline-link {
    &:deep(.chatButton--disabled) {
      cursor: auto;
      font-family: unset;
      color: $black--dark;
      &:hover {
        text-decoration: unset;
      }
    }
  }
  .form-error__chatButton {
    display: flex;
    justify-content: center;
    padding: 0 rem(16);
    height: 40px;
    white-space: nowrap;
    border-radius: 4px;
    border: solid 1px $blue;
    font-size: rem(18);
    letter-spacing: -0.2px;
    text-align: center;
    font-family: $mb-font-text;
    cursor: pointer;
    align-items: center;
    width: 100%;
    background-color: $blue;
    text-shadow: 0 0 2px $blue--dark;
    color: $white;

    @include viewport('lg') {
      min-width: 148px;
    }
  }

  .form-error__inline-chat {
    color: $blue--dark;
    display: unset;
    font-size: unset;
  }
}
</style>
