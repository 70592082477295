<template>
  <div class="os-title-input">
    <InputDropdown v-model="titleModel" :items="titles" required />
  </div>
</template>

<script lang="ts">
import { ITitle } from '~/types/forms'
import { titles } from '~/constants/forms'

export default {
  props: {
    modelValue: {
      type: Object as PropType<ITitle>,
      required: true
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const titleModel = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    return {
      titles,
      titleModel
    }
  }
}
</script>

<style lang="scss" scoped>
.os-title-input {
  max-width: 128px;
  .dropdown {
    z-index: 1;
  }
}
</style>
