<template>
  <div>
    <slot name="top" />

    <section class="os-form__section os-form__section--spaced">
      <template v-if="retailer && isFixed && isRetailerValid">
        <div class="os-form__subtitle">Your preferred Showroom</div>
        <RetailerInformation
          :retailer="retailer"
          :vehicle="vehicle"
          :is-smart="isSmart"
          :show-cloudfront-message="true"
          @go-to-results="goToResultsTracking"
        />
      </template>

      <FormsSectionInputAutocomplete
        v-else
        data-testid="input"
        :form-type="formType"
        @on-select="onSelect"
      />
    </section>

    <OsButtonTray>
      <OsButton
        :is-loading="isLoading"
        :is-disabled="invalid || isDisabled || !retailer"
        :is-primary="true"
        @click.prevent="next"
      >
        {{ isLoading ? 'Please wait' : `Next: ${nextStepTitle}` }}
      </OsButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { IRetailer } from '~/types/retailer'
import { useServiceStatusStore } from '~/stores/serviceStatusStore'
import { useRetailerStore } from '~/stores/retailerStore'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { EFormType } from '~/types/forms'
import { useFiltersStore } from '~/stores/filtersStore'

export default {
  props: {
    nextStepTitle: {
      type: String,
      required: true,
      default: ''
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: null
    },
    isSmart: {
      type: Boolean,
      required: false,
      default: false
    },
    formType: {
      type: String as PropType<EFormType>,
      required: true
    }
  },

  emits: ['view', 'next'],

  setup(props, { emit }) {
    const { $dataLayer } = useNuxtApp()
    const serviceStatusStore = useServiceStatusStore()
    const retailerStore = useRetailerStore()
    const filtersStore = useFiltersStore()

    const isLoading = ref(false)

    // Return the retailer from the filters store for stock notifications
    // otherwise return the retailer from the retailer store.
    // This is temporary.
    const retailer = computed(() => {
      if (props.formType === EFormType.StockNotification) {
        return filtersStore.retailer
      }

      return retailerStore.retailer
    })

    const isDisabled = computed<boolean>(() => {
      if (props.formType !== EFormType.Offer) return false

      return !serviceStatusStore.agency
    })

    const isRetailerValid = computed<boolean>(() => {
      if (props.isSmart) {
        return retailer.value?.smart === true
      }

      return true
    })

    const isFixed = computed<boolean>(() => isNotMBUK(retailer.value!))

    const next = () => emit('next')

    const onSelect = (retailer: IRetailer) => {
      $dataLayer.linkClick({
        pageCategory: `${props.formType}:retailer`,
        category: `${props.formType}:retailer`,
        action: retailer.Description
          ? `retailer:${retailer.Description.toLowerCase()}`
          : ''
      })
    }

    const goToResultsTracking = (trackingString: string) => {
      $dataLayer.linkClick({
        pageCategory: `${props.formType}:preferred Showroom`,
        action: `${trackingString.toLowerCase()}`
      })
    }

    onMounted(() => emit('view'))

    return {
      retailer,
      isFixed,
      isDisabled,
      isLoading,
      isRetailerValid,
      next,
      onSelect,
      goToResultsTracking
    }
  }
}
</script>
