<template>
  <div>
    <Form v-slot="{ meta }" as="div">
      <FormsSectionContactDetails class="os-form__section" />
      <FormsSectionContactBusinessDetails
        v-if="isNew"
        :is-mandatory="customerTypeStore.isBusiness"
        class="os-form__section"
      >
        <template v-if="customerTypeStore.isDefault" #label>
          <p>
            If you are going to pay via your business, please enter your
            business name to avoid delays.
          </p>
        </template>
      </FormsSectionContactBusinessDetails>

      <FormsSectionContactPreferences
        class="os-form__section"
        :form-type="formType"
        :step-title="stepTitle"
        :has-address="hasAddress"
        :is-phone-optional="isPhoneOptional"
      />
      <FormsSectionMarketingPreferences
        v-if="!isEnabled"
        class="os-form__section os-form__section--spaced"
      />

      <slot />

      <slot name="button-tray" :valid="meta.valid">
        <OsButtonTray>
          <OsButton
            v-if="nextStepTitle"
            :is-disabled="isLoading || !meta.valid"
            is-primary
            @click.prevent="next"
          >
            {{ `Next: ${nextStepTitle}` }}
          </OsButton>
        </OsButtonTray>
      </slot>
    </Form>
  </div>
</template>

<script lang="ts">
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFiltersStore } from '~/stores/filtersStore'

export default {
  props: {
    nextStepTitle: {
      type: String,
      required: true
    },
    stepTitle: {
      type: String,
      required: true
    },
    formType: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    hasAddress: {
      type: Boolean,
      required: false,
      default: true
    },
    isPhoneOptional: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['next', 'view'],

  setup(_, { emit }) {
    const customerTypeStore = useCustomerTypeStore()
    const filterStore = useFiltersStore()

    const isNew = computed(() => filterStore.isNew)

    const next = () => {
      emit('next')
    }

    onMounted(() => {
      emit('view')
    })

    const { isEnabled } = useFeatureFlag('onedoc_enabled')

    return {
      customerTypeStore,
      isNew,
      next,
      isEnabled
    }
  }
}
</script>
