<template>
  <div class="data-information">
    <div class="os-form__subtitle">Your data and Mercedes-Benz</div>
    <AccordionMenu single-item>
      <AccordionItem>
        <AccordionItemTitle>
          How we use your personal information
        </AccordionItemTitle>
        <AccordionItemContent>
          {{ dataContent.personalInformation }}
        </AccordionItemContent>
      </AccordionItem>
    </AccordionMenu>
    <AccordionMenu single-item>
      <AccordionItem>
        <AccordionItemTitle> How we keep you informed </AccordionItemTitle>
        <AccordionItemContent>
          {{ dataContent.keepYouInformed }}
        </AccordionItemContent>
      </AccordionItem>
    </AccordionMenu>
  </div>
</template>

<script lang="ts">
import { IDataProtection } from '~/types/forms'
import { dataProtection } from '~/constants/forms'

export default {
  props: {
    formType: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup() {
    const dataContent = computed<IDataProtection>(() => dataProtection)
    return {
      dataContent
    }
  }
}
</script>
