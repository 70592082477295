<template>
  <article>
    <div class="os-form__section">
      <div class="os-form__subtitle">Your contact preferences</div>
      <Field v-slot="{ errors }" :name="validationNames.email">
        <InputText
          v-model="email"
          type="email"
          :name="validationNames.email"
          rules="required|email"
          :validate-on-mount="shouldValidate('email')"
          placeholder="Email"
          :data-saved="email.length > 0"
          :validate="true"
        />
        <client-only v-if="!isEnabled">
          <Teleport to="#marketing-opt-in-port">
            <OsSelectorButton
              :is-selected="OptInEmail === true"
              :is-disabled="!!errors.length || !email"
              icon="email"
              @select="
                (OptInEmail = !OptInEmail), trackMarketingPrefs('OptInEmail')
              "
              @disabled="OptInEmail = false"
              @enabled="OptInEmail = false"
            >
              Email
            </OsSelectorButton>
          </Teleport>
        </client-only>
      </Field>

      <Field v-slot="{ errors }" :name="validationNames.phone">
        <InputText
          v-model="phone"
          type="tel"
          :name="validationNames.phone"
          :immediate="shouldValidate('phone')"
          :rules="`phone${isPhoneOptional ? '' : '|required'}`"
          :placeholder="'Phone'"
          :data-saved="phone.length > 0"
          :validate="!isPhoneOptional"
        />
        <client-only v-if="!isEnabled">
          <Teleport to="#marketing-opt-in-port">
            <OsSelectorButton
              :is-selected="OptInTelephone === true"
              :is-disabled="!!errors.length || !phone"
              icon="phone"
              @select="
                (OptInTelephone = !OptInTelephone),
                  trackMarketingPrefs('OptInTelephone')
              "
              @disabled="OptInTelephone = false"
              @enabled="OptInTelephone = false"
            >
              Phone
            </OsSelectorButton>
          </Teleport>
          <Teleport to="#marketing-opt-in-port">
            <OsSelectorButton
              :is-selected="OptInSMS === true"
              :is-disabled="!!errors.length || !phone"
              icon="SMS"
              @select="(OptInSMS = !OptInSMS), trackMarketingPrefs('OptInSMS')"
              @disabled="OptInSMS = false"
              @enabled="OptInSMS = false"
            >
              SMS
            </OsSelectorButton>
          </Teleport>
        </client-only>
      </Field>

      <div v-if="hasAddress">
        <transition name="slideDown">
          <InputAddress @updated="updateCustomer" />
        </transition>
        <client-only v-if="!isEnabled">
          <Teleport to="#marketing-opt-in-port">
            <OsSelectorButton
              :is-selected="OptInPost === true"
              :is-disabled="!hasValidAddress"
              icon="email"
              @select="
                (OptInPost = !OptInPost), trackMarketingPrefs('OptInPost')
              "
              @disabled="OptInPost = false"
              @enabled="OptInPost = false"
            >
              Post
            </OsSelectorButton>
          </Teleport>
        </client-only>
      </div>
    </div>
    <div class="os-form__section">
      <div class="os-form__subtitle">Additional information</div>
      <InputTextArea
        v-model="additionalInfo"
        placeholder="Is there any additional information that you'd like to tell us?"
      />
    </div>
  </article>
</template>

<script lang="ts">
import { useFormsStore } from '~/stores/forms/formsStore'

export default {
  name: 'FormsContactPreferences',

  props: {
    formType: {
      type: String,
      required: true
    },
    stepTitle: {
      type: String,
      required: true
    },
    hasAddress: {
      type: Boolean,
      required: false,
      default: true
    },
    isPhoneOptional: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const { $dataLayer } = useNuxtApp()
    const formsStore = useFormsStore()
    const hasValidAddress = ref(false)

    const validationNames = {
      email: 'contactPreferencesEmail',
      phone: 'contactPreferencesTel'
    }

    const userEmail = useSessionStorage('user_email', '')

    const email = computed({
      get: () => formsStore.customerDetails.email || '',
      set: (value: string) => {
        formsStore.updateCustomer({ email: value })
        userEmail.value = value
      }
    })

    const phone = computed({
      get: () => formsStore.customerDetails.phone || '',
      set: (value: string) => formsStore.updateCustomer({ phone: value })
    })

    const postcode = computed({
      get: () => formsStore.customerDetails.postcode || '',
      set: (value: string) => formsStore.updateCustomer({ postcode: value })
    })

    const additionalInfo = computed({
      get: () => formsStore.customerDetails.additionalInfo || '',
      set: (value: string) =>
        formsStore.updateCustomer({ additionalInfo: value })
    })

    const OptInEmail = computed({
      get: () => formsStore.customerDetails.OptInEmail || false,
      set: (value: boolean) => formsStore.updateCustomer({ OptInEmail: value })
    })

    const OptInTelephone = computed({
      get: () => formsStore.customerDetails.OptInTelephone || false,
      set: (value: boolean) =>
        formsStore.updateCustomer({ OptInTelephone: value })
    })

    const OptInSMS = computed({
      get: () => formsStore.customerDetails.OptInSMS || false,
      set: (value: boolean) => formsStore.updateCustomer({ OptInSMS: value })
    })

    const OptInPost = computed({
      get: () => formsStore.customerDetails.OptInPost || false,
      set: (value: boolean) => formsStore.updateCustomer({ OptInPost: value })
    })

    const shouldValidate = (key: 'email' | 'phone'): boolean =>
      !!formsStore.customerDetails[key]

    const updateCustomer = (form: any) => {
      const { street, town, postcode } = form

      formsStore.updateCustomer(form)

      hasValidAddress.value = !!(street && town && postcode)
    }

    const trackMarketingPrefs = (
      key: 'OptInPost' | 'OptInSMS' | 'OptInTelephone' | 'OptInEmail'
    ) => {
      $dataLayer.linkClick({
        pageCategory: `${props.formType}: ${props.stepTitle}`,
        category: 'marketing preferences',
        action: `${key}:${!!formsStore.customerDetails[key]}`
      })
    }

    const { isEnabled } = useFeatureFlag('onedoc_enabled')

    return {
      formsStore,
      hasValidAddress,
      email,
      phone,
      postcode,
      additionalInfo,
      OptInEmail,
      OptInTelephone,
      OptInSMS,
      OptInPost,
      validationNames,
      shouldValidate,
      updateCustomer,
      trackMarketingPrefs,
      isEnabled
    }
  }
}
</script>
