<template>
  <div>
    <form novalidate @submit.prevent>
      <div class="address-lookup">
        <Form v-slot="{ meta }" as="div" class="address-lookup__postcode">
          <div class="address-lookup__postcode__input">
            <InputText
              v-model="formAddress.postcode"
              type="text"
              name="inputAddressPostcode"
              :validate-on-mount="shouldValidate('postcode')"
              :rules="!isManual ? 'required|postcode' : ''"
              placeholder="Search by postcode"
              :disabled="isManual"
              :validate="!isManual"
              @input="postcodeChange($event)"
            />
          </div>
          <OsButton
            :has-animation="false"
            class="address-lookup__postcode__button"
            :is-loading="isLoading"
            :is-disabled="isManual || isLoading || !meta.valid"
            @click="findAddress(true)"
          >
            Find
          </OsButton>
        </Form>
        <OsError v-if="errorBag.hasErrors && !isManual">
          The entered postcode did not yield any results
        </OsError>
        <InputDropdown
          :model-value="dropdownAddress"
          :items="dropdownAddresses"
          :disabled="searchResults.length < 1 || isManual"
        />
        <AccordionButton :is-active="isManual" @event="toggleIsManual">
          {{ isManual ? 'Search by postcode' : 'Enter address manually' }}
        </AccordionButton>

        <transition name="slideDown">
          <!-- This is only hidden so we can use these fields for the validation -->
          <div v-show="isManual" class="address-lookup__inputs mt-6">
            <div v-for="{ key, title, isRequired } in formFields" :key="key">
              <InputText
                v-model="formAddress[key]"
                type="text"
                :name="`inputAddressManual_${key}`"
                :immediate="isRequired && shouldValidate(key)"
                :rules="isRequired ? 'required' : ''"
                :placeholder="title"
                :data-saved="isRequired && formAddress[key].length > 0"
                :validate="isRequired"
              />
            </div>
            <div class="address-lookup__manual__postcode">
              <InputText
                v-model="formAddress.postcode"
                type="text"
                name="inputAddressManual_postcode"
                :immediate="shouldValidate('postcode')"
                rules="required|postcode"
                placeholder="Postcode"
                :data-saved="formAddress.postcode.length > 0"
                :validate="true"
                @event="postcodeChange($event)"
              />
            </div>
          </div>
        </transition>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { IAddress } from '~/types/forms/address'
import { IDropdownItem } from '~/types/dropdown'
import { useFormsStore } from '~/stores/forms/formsStore'
import { ICustomer } from '~/types/forms/customer'

type ICustomerAddress = Pick<
  ICustomer,
  'postcode' | 'property' | 'street' | 'streetTwo' | 'town' | 'county'
>
type AddressLookup = Omit<
  ICustomerAddress,
  'postcode' | 'property' | 'street' | 'streetTwo' | 'town' | 'county'
> & {
  postcode: string
  property: string
  street: string
  streetTwo: string
  town: string
  county: string
}

export default {
  emits: ['updated'],

  setup(_, { emit }) {
    const { $api } = useNuxtApp()
    const formsStore = useFormsStore()

    const isManual = ref<boolean>(false)
    const isLoading = ref<boolean>(false)

    const { postcode, property, street, streetTwo, town, county } =
      formsStore.getCustomer
    const formAddress = ref<AddressLookup>({
      postcode: postcode || '',
      property: property || '',
      street: street || '',
      streetTwo: streetTwo || '',
      town: town || '',
      county: county || ''
    })

    const formFields: {
      key: keyof Partial<AddressLookup>
      title: string
      isRequired?: boolean
    }[] = [
      { key: 'property', title: 'House name / number', isRequired: true },
      { key: 'street', title: 'Address line 1', isRequired: true },
      { key: 'streetTwo', title: 'Address line 2' },
      { key: 'town', title: 'Town / City', isRequired: true },
      { key: 'county', title: 'County' }
    ]

    onMounted(async () => {
      if (formAddress.value.postcode.length) {
        await findAddress()

        if (!formAddress.value) clearForm()
      }
    })

    watch(
      formAddress,
      () => {
        emit('updated', formAddress.value)
      },
      { deep: true }
    )

    const shouldValidate = (key: keyof AddressLookup): boolean => {
      if (isManual.value) return false

      return !!formAddress.value[key]
    }

    // clear everything but postcode
    const clearForm = () => {
      formAddress.value = {
        ...formAddress.value,
        property: '',
        street: '',
        streetTwo: '',
        town: '',
        county: ''
      }
    }

    const toggleIsManual = () => {
      // if closing manual address - update formAddress with the dropdown selectedAddress
      if (isManual.value) {
        selectedAddress.value
          ? (formAddress.value = useCloneDeep(selectedAddress.value))
          : clearForm()
      }

      isManual.value = !isManual.value
    }

    // dropdown
    const searchResults = ref<AddressLookup[]>([])
    const selectedAddress = ref<AddressLookup>()
    const errorBag = ref<ErrorBag>(new ErrorBag())

    const dropdownAddress = computed<IDropdownItem>(() => {
      if (!selectedAddress.value) return { id: '', text: 'Select an address' }

      const { property, street, town } = selectedAddress.value
      return {
        id: `${property || ''} ${street || ''} ${town || ''}`,
        text: `${property || ''} ${street || ''} ${town || ''}`
      }
    })

    const dropdownAddresses = computed<IDropdownItem[]>(() =>
      searchResults.value.map((address, index) => {
        const label = `${address.property} ${address.street} ${address.town}`
        return {
          id: `${label}_${index}`,
          text: label,
          onSelect: () => selectAddress(address)
        }
      })
    )

    const clearResults = () => {
      searchResults.value = []
      errorBag.value.clear()
      selectedAddress.value = undefined
    }

    const selectAddress = (address: AddressLookup) => {
      selectedAddress.value = useCloneDeep(address)
      formAddress.value = useCloneDeep(address)

      if (
        !address.property ||
        !address.street ||
        !address.town ||
        !address.postcode
      ) {
        isManual.value = true
      }
    }

    const postcodeChange = (e: { inputType: string }) => {
      errorBag.value.clear()

      if (e.inputType === 'deleteContentBackward') {
        clearResults()
        !isManual.value && clearForm()
      }
    }

    const findAddress = async (selectDefault?: boolean) => {
      isLoading.value = true
      clearResults()

      formAddress.value.postcode = formatPostcode(formAddress.value.postcode)

      const addressesResponse = await $api.myAccount.address.postcodeLookup(
        formAddress.value.postcode
      )

      if (addressesResponse.error) {
        isLoading.value = false
        errorBag.value.add('Address error')

        return clearForm()
      }

      searchResults.value = addressesResponse.data.Addresses.map(
        (address: IAddress) => {
          const addressFormatted: AddressLookup = {
            postcode: address.Postcode || '',
            property: address.Property || address.Organisation || '',
            street: address.Street || '',
            streetTwo: address.Locality || '',
            town: address.Town || '',
            county: address.County || ''
          }

          if (
            !selectDefault &&
            formAddress.value.property === address.Property &&
            formAddress.value.street === address.Street &&
            formAddress.value.postcode === address.Postcode
          ) {
            selectAddress(addressFormatted)
          }

          return addressFormatted
        }
      )

      if (selectDefault) {
        selectAddress(searchResults.value[0])
      }

      if (!selectedAddress.value) clearForm()

      isLoading.value = false
    }

    return {
      formFields,
      shouldValidate,
      isManual,
      postcodeChange,
      isLoading,
      findAddress,
      dropdownAddress,
      dropdownAddresses,
      searchResults,
      errorBag,
      formAddress,
      toggleIsManual,
      selectedAddress
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown {
  grid-template-columns: minmax(0, 1fr);
  display: grid;
}

.address-lookup {
  display: flex;
  flex-direction: column;

  .accordionButton {
    align-self: flex-end;
    padding-right: 0;
    margin-top: rem(8);

    svg {
      width: 16px;
    }
  }
}
.address-lookup__postcode {
  display: flex;
}

.address-lookup__postcode__input {
  margin-bottom: rem(16);
  margin-right: rem(4);
  max-width: 196px;
  width: 100%;

  &:deep(input) {
    text-transform: uppercase;
  }
}

.address-lookup__postcode__button {
  height: 56px;
  width: 100px;
}

.address-lookup__manual__postcode {
  input {
    text-transform: uppercase;
  }
}

.address-lookup__inputs {
  display: grid;
  grid-gap: rem(16);
}
</style>
