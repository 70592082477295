<template>
  <OsCard>
    <OsCardContent>
      <h6 class="mb-2">UK Data Protection Co-ordinator</h6>
      <div class="text-base mb-6">
        Legal Department<br />
        Mercedes-Benz UK Ltd<br />
        Tongwell, Milton Keynes<br />
        MK15 8BA
      </div>
      <a href="mailto:datacompliance@mercedes-benz.com" class="inline-link">
        <OsIcon name="Email" class="text-blue-600" />
        <span>datacompliance@mercedes-benz.com</span>
      </a>
    </OsCardContent>
  </OsCard>
</template>
