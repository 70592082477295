<template>
  <div class="form-list">
    <ul :class="{ bullet: !hasIcon }">
      <li v-for="item in formattedList" :key="item.text">
        <OsIcon v-if="item.icon" :name="item.icon" fill="#919191" width="14" />
        <span>{{ item.text }}</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { IFormListItem } from '~/types/forms'

export default {
  props: {
    list: {
      type: Array as PropType<(IFormListItem | string)[]>,
      required: false,
      default() {
        return []
      }
    }
  },
  setup(props) {
    const formattedList = computed<IFormListItem[]>(() => {
      if (typeof props.list?.[0] === 'string') {
        return props.list.map((l) => ({
          text: l
        }))
      }

      return props.list
    })

    const hasIcon = computed(() => props.list.some((l) => !!l.icon))

    return { formattedList, hasIcon }
  }
}
</script>

<style scoped lang="scss">
.form-list {
  ul.bullet {
    padding: 0 rem(20) 0 0;
  }

  li:not(:last-child) {
    margin-bottom: rem(24);
  }

  ul:not(.bullet) {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      font-size: rem(14);

      span {
        margin-left: rem(6);

        // if it's a list with icons but one item doens't have icon, keep it align with the rest
        &:only-child {
          margin-left: rem(20);
        }
      }
    }

    svg {
      flex: 0 0 auto;
    }
  }
}
</style>
