<template>
  <div v-if="status !== 'ignored'" class="os-form__section">
    <div class="os-form__subtitle">Your marketing preferences</div>

    <div class="block">
      <p v-if="status === 'completed'">
        Thank you for providing your marketing preferences.
      </p>

      <p v-else-if="status === 'error'">Something went wrong.</p>

      <p v-else-if="status === 'skipped'">
        You have skipped providing your marketing preferences. We will ask you
        again next time.
      </p>

      <iframe
        v-else
        ref="iframe"
        width="100%"
        :height="iFrameHeight + 'px'"
        src="https://docms-int.i.mercedes-benz.com/oneDoCSPA"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useFormsStore } from '~/stores/forms/formsStore'
import type { ICustomer } from '~/types/forms/customer'

const formsStore = useFormsStore()

const customer = computed<ICustomer>(() => formsStore.getCustomer)

const customerDataJson = computed(() => {
  return {
    s: 'OnlineShowroomPC',
    m: 'GB',
    le: 'D-Outlet.1452815', // Need to confirm this
    l: 'en-GB',
    loc: 'en-GB',
    ds: '1',
    pnf: customer.value.firstName,
    pnl: customer.value.lastName,
    tt: 'M',
    tn: customer.value.phone,
    ea: customer.value.email,
    ah: customer.value.property,
    as: customer.value.street,
    azip: customer.value.postcode,
    country: 'GB',
    theme: 'oneweb'
  }
})

const iframe = ref(null)
const iFrameHeight = ref(0)

const hasInitialised = ref(false)
const status: Ref<'pending' | 'ignored' | 'skipped' | 'completed' | 'error'> =
  ref('pending')

const initialise = () => {
  iframe.value?.contentWindow?.postMessage(
    `spaCustomerDataMessage(${JSON.stringify(customerDataJson.value)})`,
    '*'
  )

  hasInitialised.value = true
}

const extractNumber = (str: String) => {
  const regex = /\d+/ // This regex matches one or more digits
  const result = str.match(regex) // Use match() to find the digits

  return result ? Number(result[0]) : null // Convert the result to a number if any digits were found
}

const { $dataLayer } = useNuxtApp()

const { stop } = useIntersectionObserver(iframe, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    $dataLayer.eecImpression({ category: 'onedoc_seen' })
    stop()
  }
})

const trackEvent = (
  category: string,
  action: string,
  type: 'click' | 'message' = 'message'
) => {
  const payload = {
    category,
    action
  }

  if (type === 'click') {
    $dataLayer.linkClick(payload)
  }

  if (type === 'message') {
    $dataLayer.systemMessageEvent(payload)
  }
}

useEventListener(window, 'message', (e) => {
  if (!hasInitialised.value) initialise()

  // check received message
  if (e && e.data) {
    console.log('message', e.data)
    const start = e.data.indexOf('(')
    const end = e.data.lastIndexOf(')')

    if (start === -1 || end === -1) {
      status.value = 'error'
    }

    if (e.data.startsWith('setIframeHeight')) {
      iFrameHeight.value = extractNumber(e.data) || 0

      return
    }

    const method = e.data.substring(0, start)
    const data = e.data.substring(start + 1, end)
      ? JSON.parse(e.data.substring(start + 1, end))
      : null

    switch (method) {
      case 'spaCompletedCallback':
        console.log('spaCompletedCallback', data)
        trackEvent('onedoc_completed', '')
        status.value = 'completed'

        break
      case 'spaErrorCallback':
        console.log('spaErrorCallback', data)
        trackEvent('onedoc_error', data?.resultMessage, 'message')
        status.value = 'error'

        break
      case 'spaSkippedCallback':
        console.log('spaSkippedCallback', data)
        if (data?.result === 'CONSENT_SKIPPED_DATA_UPSERT') {
          trackEvent('onedoc_customer_skip', '')
          status.value = 'skipped'
        } else {
          trackEvent('onedoc_skip', '', 'message')
          status.value = 'ignored'
        }

        break
    }
  }
})
</script>
