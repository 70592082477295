<template>
  <div>
    <FormsSectionMarketingDataInformation class="os-form__section" />
    <article class="os-form__section">
      <div class="os-form__subtitle">Your marketing preferences</div>
      <p class="os-form__disclaimer">
        Please tell us how you'd like to hear from us:
      </p>
      <div id="marketing-opt-in-port" />
      <p class="os-form__disclaimer">
        {{ dataProtection.personalData }}
      </p>
      <p class="os-form__disclaimer">
        {{ dataProtection.marketingPreferences }}
      </p>
    </article>
    <FormsSectionMarketingDataProtection />
  </div>
</template>

<script lang="ts" setup>
import { dataProtection } from '~/constants/forms'
</script>

<style scoped lang="scss">
#marketing-opt-in-port {
  display: flex;
  gap: rem(16);
  z-index: auto;
}
</style>
