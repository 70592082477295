<template>
  <article>
    <div class="os-form__subtitle">Your business details</div>
    <slot name="label"></slot>
    <InputText
      v-model="companyName"
      type="text"
      name="businessDetailsCompanyName"
      :validate-on-mount="shouldValidate('companyName')"
      :rules="isMandatory ? 'required' : ''"
      placeholder="Business Name"
      :validate="true"
    />
  </article>
</template>

<script lang="ts">
import { useFormsStore } from '~/stores/forms/formsStore'

export default {
  props: {
    isMandatory: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    const formsStore = useFormsStore()

    const shouldValidate = (key: 'companyName'): boolean => {
      return !!formsStore.customerDetails[key]
    }

    const companyName = computed({
      get: () => {
        return formsStore.customerDetails.companyName || ''
      },
      set: (value: string) => {
        formsStore.updateCustomer({ companyName: value })
      }
    })

    return {
      shouldValidate,
      companyName
    }
  }
}
</script>
