<template>
  <div class="forms-content">
    <slot name="steps">
      <div class="os-form__disclaimer mb-2">
        {{ currentStep.label }}
      </div>
    </slot>

    <FormsError
      v-if="hasError"
      :form-error-bag="formErrorBag"
      :form-type="formType"
      @start-again="startAgain()"
    />

    <!-- Use show only so the component is still alive, 
      otherwise if it sent any event it doesn't finish it -->
    <div v-show="!hasError">
      <slot name="title">
        <div
          v-if="currentStep && (currentStep.title || currentStep.intro)"
          class="os-form__header"
        >
          <h2 v-if="currentStep.title" class="os-form__title">
            {{ currentStep.title }}
          </h2>
          <p v-if="currentStep.intro">
            {{ currentStep.intro }}
          </p>
        </div>
      </slot>

      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { EFormType } from '~/types/forms'
import { IStep } from '~/types/steps'

export default {
  props: {
    formErrorBag: {
      type: Object as PropType<FormErrorBag>,
      default: () => new FormErrorBag()
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    currentStep: {
      type: Object as PropType<IStep>,
      required: false,
      default: () => ({})
    },
    formType: {
      type: String as PropType<EFormType>,
      required: true
    }
  },

  emits: ['start-again'],

  setup(props, { emit }) {
    const startAgain = () => {
      emit('start-again')
    }

    return {
      startAgain
    }
  }
}
</script>

<style lang="scss">
// this plus the padding at the bottom of the sidepanel makes the distance between the button and the end of the form 56px
.sidepanel__contents__wrap .forms-content {
  margin-bottom: 32px;
}
</style>

<style scoped lang="scss">
// global css for all forms
.forms-content:deep(.os-form__subtitle + .financeTable) {
  .financeTable__group:first-child {
    border: none;
    padding: 0;

    .financeTable__item__label--product {
      margin-top: 0;
    }
  }
}
</style>
