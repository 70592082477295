import { EFormType, IFormStep } from '~/types/forms'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useRetailerStore } from '~/stores/retailerStore'
import { IStep } from '~/types/steps'
import { IFormNavigation } from '~/types/forms/formNavigation'

export const useOsForm = <T extends string>(
  store: any,
  componentDictionary
) => {
  const { currentStep, getMappedSteps } = store.navigation

  const currentComponent = computed<any>(
    () => componentDictionary.value[currentStep.value?.label as T]
  )

  const hasError = computed(() => store.hasError)

  const isStep = (step: T): boolean =>
    currentComponent.value === componentDictionary.value[step]

  const mappedSteps = computed<IFormStep[]>(getMappedSteps)

  provide('currentStep', currentStep)

  return {
    currentComponent,
    hasError,
    isStep,
    mappedSteps
  }
}

export const useFormTracking = (
  formType: EFormType,
  currentStep?: Ref<IStep>
) => {
  const formsStore = useFormsStore()
  const retailerStore = useRetailerStore()
  const { $dataLayer } = useNuxtApp()

  const trackingProps = computed(() => {
    const stepLabel =
      currentStep?.value.trackingLabel || currentStep?.value.label
    const retailer = retailerStore.retailer

    return {
      pageCategory: `${formType.toLowerCase()}:${stepLabel?.toLowerCase()}`,
      user: { preferred_retailer: retailer?.Description || '' },
      marketingPreference: formsStore.getMarketingPreferences,
      ...(formsStore.getVehicle
        ? { vehicle: vehicleAnalytics(formsStore.getVehicle) }
        : {})
    }
  })

  const trackPageView = (trackingPropsAttr: any = {}) => {
    $dataLayer.pageView({ ...trackingProps.value, ...trackingPropsAttr })
  }

  return {
    trackPageView,
    trackingProps
  }
}

export const useFormHeader = (navigation?: IFormNavigation) => {
  const newBreadcrumbText = computed<string>(() => {
    if (!navigation) return ''

    const currentIndex = navigation.currentStepIndex.value + 1
    const totalSteps = navigation.steps.value.length
    return `${currentIndex} of ${totalSteps}`
  })

  return {
    newBreadcrumbText
  }
}
