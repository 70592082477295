<template>
  <article class="formsInputAutocomplete">
    <div class="os-form__subtitle">Select your preferred Showroom</div>
    <Field
      v-slot="fieldProps"
      name="formInputAutocomplete"
      :model-value="query"
      :validate-on-mount="hasRetailer && !isLoading"
      :rules="rules"
    >
      <div v-click-outside="close" class="formsInputAutocomplete__field">
        <InputLocation
          label="Postcode or Showroom name"
          class="formsInputAutocomplete__field__input"
          :model-value="query"
          :is-loading="isLoading"
          :results="filteredRetailers"
          :validate="true"
          :pattern="'[a-zA-Z0-9\- ]+'"
          :is-error-state="!!fieldProps?.errors?.length || false"
          :hide-results="!isDropdownOpen"
          @update:model-value="onLocationInput"
          @located="onLocated"
          @selected="onSelectRetailer"
          @focus="onInputFocus"
        >
          <template #default="{ result }">
            <span>
              {{ retailerDescription(result) }}
            </span>
            <span v-if="result.DriveTime" class="retailerAutocomplete__time">
              {{ asMinutes(result.DriveTime) }}
            </span>
          </template>
        </InputLocation>

        <div v-if="!isLoading">
          <OsError v-if="errorBag.hasErrors">
            {{ errorBag.first }}
          </OsError>
          <OsError v-else-if="fieldProps?.errors?.length">
            {{ fieldProps?.errors[0] }}
          </OsError>
        </div>
      </div>
    </Field>
  </article>
</template>

<script lang="ts">
import clickOutside from '~/directives/clickOutside'
import { IRetailer } from '~/types/retailer'
import { useStockNotificationStore } from '~/stores/forms/stockNotificationStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { EFormType } from '~/types/forms'
import { ERouteConditions } from '~/types/routes'
import { useRetailerSearch } from '~/composables/useRetailerSearch'
import { EVehicleType } from '~/types/vehicle'
import { useRetailerStore } from '~/stores/retailerStore'
import { useFiltersStore } from '~/stores/filtersStore'
import ErrorMessages from '~/constants/errorMessages'
import { useUi } from '~/composables/useUi'

export default {
  name: 'FormsSectionInputAutocomplete',

  directives: {
    clickOutside
  },

  props: {
    formType: {
      type: String as PropType<EFormType>,
      required: false,
      default: ''
    }
  },

  emits: ['input', 'on-select'],

  setup(props, { emit }) {
    const formsStore = useFormsStore()
    const retailerStore = useRetailerStore()
    const stockNotificationStore = useStockNotificationStore()
    const filtersStore = useFiltersStore()

    const { asMinutes } = useUi()

    const vehicle = computed(() => formsStore.getVehicle)
    const hasRetailer = computed(() => retailerStore.hasRetailer)
    const retailerSelected = computed(() => retailerStore.retailer)

    const updateRetailer = (retailer: IRetailer | null) => {
      if (!retailer) return

      // Update the retailer in the filters store for stock notifications
      // otherwise update the retailer in the retailer store.
      // This is temporary.
      isStockNotification.value
        ? filtersStore.updateRetailer(retailer)
        : retailerStore.updateRetailer(retailer)
    }

    const isCallback = computed(() => props.formType === EFormType.Callback)
    const isStockNotification = computed(
      () => props.formType === EFormType.StockNotification
    )

    const route = useRoute()

    const isVehicleNew = computed(
      () =>
        vehicle.value?.VehicleType === EVehicleType.New ||
        route.params?.condition === ERouteConditions.New
    )

    const isSmart = computed(() => {
      if (isStockNotification.value) {
        return stockNotificationStore.isSmart
      }

      return !isCallback.value && vehicle.value?.Brand?.Description === 'SMART'
    })

    // TODO: [Retailer] Convert this component to InputRetailer
    const {
      query,
      isDropdownOpen,
      retailers,
      errorBag,
      close,
      isLoading,
      onLocated,
      selectRetailer,
      handleInput,
      retailerDescription,
      rules
    } = useRetailerSearch(onRetailerSearch, updateRetailer, {
      isSmart: isSmart.value
    })

    const filteredRetailers = computed<IRetailer[]>(() => {
      const availableRetailers = filterRetailersBy(retailers.value)

      const conditionRetailers = filterRetailersBy(
        availableRetailers,
        isVehicleNew.value ? 'IsNewCarRetailer' : 'IsUsedCarRetailer'
      )

      if (isSmart.value) {
        return filterRetailersBy(conditionRetailers, 'smart').slice(0, 3)
      }

      return conditionRetailers.slice(0, 3)
    })

    const onLocationInput = (value: string) => {
      handleInput(value, retailerSelected.value)
      emit('input', value)
    }

    const onSelectRetailer = (retailer: IRetailer) => {
      selectRetailer(retailer)
      emit('on-select', retailer)
    }

    const isRetailerSelected = computed<boolean>(() => {
      return query.value === retailerDescription(retailerStore.retailer!)
    })

    function onRetailerSearch() {
      if (filteredRetailers.value.length === 0 && !isRetailerSelected.value) {
        errorBag.value.add(ErrorMessages.showroomSearch.empty)
      }
    }
    const onInputFocus = () => {
      if (filteredRetailers.value.length) {
        isDropdownOpen.value = true
      }
    }

    const isValidRetailer = computed<boolean>(() => {
      if (!retailerSelected.value) return false

      if (isMBUK(retailerSelected.value)) return false

      return isSmart.value ? retailerSelected.value?.smart === true : true
    })

    onMounted(() => {
      if (!isValidRetailer.value) {
        retailerStore.resetRetailer()
      }

      if (hasRetailer.value) {
        query.value = retailerDescription(retailerStore.retailer!)
      }
    })

    return {
      isDropdownOpen,
      close,
      query,
      rules,
      errorBag,
      isSmart,
      isLoading,
      asMinutes,
      retailerDescription,
      hasRetailer,
      onLocationInput,
      onSelectRetailer,
      filteredRetailers,
      onLocated,
      onInputFocus
    }
  }
}
</script>
<style lang="scss">
.formsInputAutocomplete {
  margin-bottom: rem(32);
}

.formsInputAutocomplete__field {
  position: relative;
  width: 100%;
  height: 57px;
}
</style>
