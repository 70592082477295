import { EConsents } from '~/types/usercentrics'

export enum EOptimizelyEvent {
  StockNotificationStart = 'stock_notification_start',
  OrderFormStart = 'order_form_start',
  TestDriveStart = 'test_drive_start',
  ValueMyCarStart = 'value_my_car_start',
  OcdFormStart = 'ocd_form_start',
  EnquiryComplete = 'enquiry_complete',
  StockNotificationComplete = 'stock_notification_complete',
  OrderFormComplete = 'order_form_complete',
  TestDriveComplete = 'test_drive_complete',
  ValueMyCarComplete = 'value_my_car_complete',
  OcdFormComplete = 'ocd_form_complete',
  EnquiryStart = 'enquiry_start',
  CrossSellClick = 'cross_sell_click'
}

/**
 * Sends tracking event to optimizely
 * @param eventType:EOptimizelyEvent
 * @returns void
 */
export const trackOptimizelyEvent = (eventType: EOptimizelyEvent): void => {
  const { isEnabled } = useFeatureFlag('optimizely_enabled')
  const { hasConsent } = useConsent(EConsents.Optimizely)

  if (isEnabled.value && hasConsent.value) {
    window.optimizely = window.optimizely || []
    window.optimizely.push({
      type: 'event',
      eventName: eventType
    })
  }
}
