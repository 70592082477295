<template>
  <button
    class="selectorButton"
    :class="[
      isSelected && 'selectorButton--active',
      isDisabled && 'selectorButton--disabled'
    ]"
    @click.prevent="update"
  >
    <span class="selectorButton__label">
      <slot />
    </span>
    <OsIcon
      v-if="!imageUrl && icon"
      :name="icon"
      :class="isSelected ? 'text-blue-600' : 'text-gray-400'"
    />
    <img v-if="imageUrl && imageUrl.length > 0" :src="imageUrl" alt="icon" />
  </button>
</template>

<script lang="ts">
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    imageUrl: {
      type: String,
      required: false,
      default: undefined
    },
    icon: {
      type: String,
      required: false,
      default: undefined
    }
  },

  emits: ['disabled', 'enabled', 'select'],

  setup(props, { emit }) {
    watch(
      () => props.isDisabled,
      (value) => {
        if (value) {
          emit('disabled')
        } else {
          emit('enabled')
        }
      },
      { immediate: true }
    )

    const update = () => {
      if (!props.isDisabled) {
        emit('select')
      }
    }

    return {
      update
    }
  }
}
</script>

<style lang="scss" scoped>
.selectorButton {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: rem(8);
  min-height: rem(56);
  border-radius: rem(4);
  background-color: $white;
  border: solid 1px $grey--light;
  cursor: pointer;
  color: $grey--darkest;
  width: 100%;

  @include transitions();

  &:hover:not(.selectorButton--active) {
    border: solid 1px currentColor;
    color: $black--light;
  }

  svg path {
    color: currentColor;
  }

  svg {
    min-width: rem(16);
    min-height: rem(16);
    max-height: rem(24);
    max-width: 100%;

    &.amgIcon {
      width: rem(70);
      height: rem(16);
      color: inherit;
    }

    &.eqIcon {
      width: 100%;
      height: rem(16);
      padding-right: rem(5);
    }
  }

  img {
    width: rem(32);
    height: rem(32);
    border-radius: rem(32);
  }
}

.selectorButton__label {
  font-size: rem(16);
  line-height: 1;
  text-align: left;
}

.selectorButton--active {
  color: $blue;
  border-color: currentColor;

  svg path {
    color: inherit;
    fill: currentColor;
  }
}

.selectorButton--disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
