<template>
  <article>
    <div class="os-form__subtitle">Your contact details</div>

    <Field v-model="title" name="contactDetailsTitle" rules="required|title">
      <InputTitle v-model="title" />
    </Field>

    <div class="row">
      <div class="col-6">
        <InputText
          v-model="firstName"
          name="contactDetailsFirstName"
          rules="required|max_character|min_character|name_field_regex|exclude_test"
          :validate-on-mount="shouldValidate('firstName')"
          type="text"
          placeholder="First name"
          :data-saved="firstName.length > 0"
          :validate="true"
          @blur="firstName = firstName.trim()"
        />
      </div>
      <div class="col-6">
        <InputText
          v-model="lastName"
          name="contactDetailsLastName"
          rules="required|max_character|min_character|name_field_regex|exclude_test"
          :validate-on-mount="shouldValidate('lastName')"
          type="text"
          placeholder="Last name"
          :data-saved="lastName.length > 0"
          :validate="true"
          @blur="lastName = lastName.trim()"
        />
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import { ITitle } from '~/types/forms'
import { useFormsStore } from '~/stores/forms/formsStore'

export default {
  setup() {
    const formsStore = useFormsStore()

    const shouldValidate = (key: 'firstName' | 'lastName'): boolean => {
      return !!formsStore.customerDetails[key]
    }

    const title = computed({
      get: () => {
        return formsStore.customerDetails.title as ITitle
      },
      set: (value: ITitle) => {
        formsStore.updateCustomer({ title: value })
      }
    })

    const firstName = computed({
      get: () => {
        return formsStore.customerDetails.firstName || ''
      },
      set: (value: string) => {
        formsStore.updateCustomer({ firstName: value })
      }
    })

    const lastName = computed({
      get: () => {
        return formsStore.customerDetails.lastName || ''
      },
      set: (value: string) => {
        formsStore.updateCustomer({ lastName: value })
      }
    })

    return {
      shouldValidate,
      title,
      firstName,
      lastName
    }
  }
}
</script>
